<template>
  <div class="selection-details">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.selection-details {
  background-color: var(--color-dark-5);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.4rem;
  padding: 1.6rem;
  margin-top: 3.2rem;
  border-radius: 0.4rem;
}
</style>
