import { defineStore } from 'pinia'
import { bleDevice, bleDeviceType } from '@/types/device'

export const useDeviceStore = defineStore('deviceStore', {
  state: (): {
    bleDevices: Array<bleDevice>
  } => ({
    bleDevices: [],
  }),
  getters: {
    getDevicesByType:
      (state) =>
        (deviceType: bleDeviceType): Array<bleDevice> => {
          return state.bleDevices.filter((x) => x.name === deviceType)
        },
  },
  actions: {
    addHrDeviceToList(bleDevice: bleDevice) {
      if (!this.bleDevices.some((x) => x.id === bleDevice.id)) {
        this.bleDevices.push(bleDevice)
      }
    },
  },
})
