import { defineStore } from 'pinia'
import type { measurementType, measurementHardware } from '@/types/measurement'
import type { position } from '@/types/position'

export const useMeasurementStore = defineStore('measurementStore', {
  state: (): {
    measurements: measurementType[]
    selected: number
  } => ({
    measurements: [],
    selected: 0,
  }),
  getters: {
    selectedMeasurement: (state) => state.measurements[state.selected],

    firstMeasurement: (state) => state.measurements[0],

    latestMeasurement: (state) =>
      state.measurements[state.measurements.length - 1],

    finalMeasurement: (state) =>
      state.measurements[state.measurements.length - 2],
  },
  actions: {
    create(measurement: measurementType) {
      this.measurements.push(measurement)
      this.selected = this.measurements.length - 1
    },

    setSelected(index: number) {
      this.selected = index
    },

    updateSelected(position: position) {
      if (this.selectedMeasurement) {
        this.selectedMeasurement.handlebar.x = position.handlebarX
        this.selectedMeasurement.handlebar.y = position.handlebarY
        this.selectedMeasurement.saddle.x = position.saddleX
        this.selectedMeasurement.saddle.y = position.saddleY
      }
    },

    updateHardware(index: number, hardware: measurementHardware) {
      const current = this.measurements[index]

      if (current) {
        this.measurements[index] = {
          crankset: {
            active: hardware.crankset.active,
          },
          handlebar: {
            ...current.handlebar, // x, y
            active: hardware.handlebar.active,
          },
          saddle: {
            ...current.saddle, // x, y
            active: hardware.saddle.active,
          },
          pedal: {
            active: hardware.pedal.active,
          },
        }
      }
    },

    delete(index: number) {
      if (index == this.selected) {
        this.selected = this.measurements.length - 2
      } else if (this.selected && index < this.selected) {
        this.selected--
      }

      this.measurements.splice(index, 1)
    },
  },
  persist: {
    storage: sessionStorage,
  },
})
