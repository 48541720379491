import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = 'https://uatservices.bikefitting.com/'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'

axios.defaults.paramsSerializer = (p) =>
  qs.stringify(p, { arrayFormat: 'brackets' })

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)
