import type { handlebarFilters, handlebarType } from '@/types/handlebar'
import axios from 'axios'

export default class {
  static async fetchHandlebars({
    sessionId = undefined,
    filters = undefined,
  }: {
    sessionId?: string
    filters?: handlebarFilters
  } = {}) {
    return axios.post<handlebarType>('Hardware/RetrieveHandlebars', {
      sessionId: sessionId,
      ...filters,
    })
  }
}
