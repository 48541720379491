import { storeToRefs } from 'pinia'
import { useMqttStore } from '@/store/mqttStore'
import { MqttValue, MqttMessage } from '../mqttDefinitions'
import {
  handlebarCurrentPositionX,
  handlebarCurrentPositionY,
  saddleCurrentPositionX,
  saddleCurrentPositionY,
} from '@/mqttDefinitions'

const translations = new Map<string, any>()

translations.set(
  'bikefitting/motorcontroller/0/current_setpoint',
  saddleCurrentPositionX,
)
translations.set(
  'bikefitting/motorcontroller/1/current_setpoint',
  saddleCurrentPositionY,
)
translations.set(
  'bikefitting/motorcontroller/2/current_setpoint',
  handlebarCurrentPositionX,
)
translations.set(
  'bikefitting/motorcontroller/3/current_setpoint',
  handlebarCurrentPositionY,
)

export default class {
  static async publish(definition: MqttValue, newValue: number) {
    const reply = translations.get(definition.topic)

    if (reply) {
      const mqttStore = useMqttStore()
      const { client } = storeToRefs(mqttStore)

      const message = {
        topic: reply.topic,
        payload: newValue,
      } as MqttMessage

      if (client.value.connected) {
        try {
          let preSubmitOutput

          if (reply.preSubmitFunction) {
            preSubmitOutput = reply.preSubmitFunction(
              message,
              reply.conversionValue,
            )
          } else {
            preSubmitOutput = {
              topic: message.topic,
              payload: JSON.stringify({
                value: message.payload,
              }),
            }
          }

          await client.value.publish(
            preSubmitOutput.topic,
            preSubmitOutput.payload,
          )

          console.log(
            `published message via simulate: ${preSubmitOutput.payload} to topic: ${preSubmitOutput.topic}`,
          )
        } catch (error) {
          console.log(`publish error:, ${error}`)
        }
      } else {
        console.log('publish error: client is not connected')
      }
    }
  }
}
