import { MqttContainer, MqttValue } from './types'

export const xyToolHandlebarX: MqttValue = {
  title: 'XY-Tool Handlebar x',
  unit: 'mm',
  topic: 'bikefitting/XYtool/handleBar/x',
}

export const xyToolHandlebarY: MqttValue = {
  title: 'XY-Tool Handlebar y',
  unit: 'mm',
  topic: 'bikefitting/XYtool/handleBar/y',
}

export const xyToolPositionX: MqttValue = {
  title: 'XY-Tool Position x',
  unit: 'mm',
  topic: 'bikefitting/XYtool/position/x',
}

export const xyToolPositionY: MqttValue = {
  title: 'XY-Tool Position y',
  unit: 'mm',
  topic: 'bikefitting/XYtool/position/y',
}

export const xyToolSaddleX: MqttValue = {
  title: 'XY-Tool Saddle x',
  unit: 'mm',
  topic: 'bikefitting/XYtool/saddle/x',
}

export const xyToolSaddleY: MqttValue = {
  title: 'XY-Tool Saddle y',
  unit: 'mm',
  topic: 'bikefitting/XYtool/saddle/y',
}

export const xyToolBrakesX: MqttValue = {
  title: 'XY-Tool Brakes x',
  unit: 'mm',
  topic: 'bikefitting/XYtool/brakes/x',
}

export const xyToolBrakesY: MqttValue = {
  title: 'XY-Tool Brakes y',
  unit: 'mm',
  topic: 'bikefitting/XYtool/brakes/y',
}

export const xyToolDefinitions: MqttContainer = {
  title: 'XY-Tool',
  prefix: 'XYtool/',
  values: [
    xyToolHandlebarX,
    xyToolHandlebarY,
    xyToolPositionX,
    xyToolPositionY,
    xyToolSaddleX,
    xyToolSaddleY,
    xyToolBrakesX,
    xyToolBrakesY,
  ],
}

export default xyToolDefinitions
