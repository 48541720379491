import { defineStore } from 'pinia'

export const useProgressStore = defineStore('progressStore', {
  state: () => ({
    step: 1,
    hardwareActive: false,
  }),
  actions: {
    setStep(step: number) {
      this.step = step
    },
  },
  getters: {
    getStep: (state) => state.step,
  },
  persist: {
    storage: sessionStorage,
  },
})
