import { bikeList } from '@/types/bike'
import { measurementType } from '@/types/measurement'
import axios from 'axios'

export default class {
  static async fetchBikes({
    customerId = undefined,
    bikeNo = 0,
    dealerId = undefined,
    sessionId = undefined,
  }: {
    customerId?: string
    bikeNo?: number
    dealerId?: string
    sessionId?: string
  } = {}) {
    return axios.post<bikeList>('Hardware/RetrieveBikes', {
      CustomerId: customerId,
      BikeNo: bikeNo,
      Dealerid: dealerId,
      SessionId: sessionId,
    })
  }

  static async putXY({
    customerId = undefined,
    dealerId = undefined,
    bikeNo = 0,
    sessionId = undefined,
    measurement = undefined,
  }: {
    customerId?: string
    dealerId?: string
    bikeNo?: number
    sessionId?: string
    measurement?: measurementType
  } = {}) {
    return axios.post('Hardware/UpdateXY', {
      CustomerId: customerId,
      BikeNo: bikeNo,
      Dealerid: dealerId,
      SessionId: sessionId,
      SaddleX: measurement?.saddle.x,
      SaddleY: measurement?.saddle.y,
      HandlebarX: measurement?.handlebar.x,
      HandlebarY: measurement?.handlebar.y,
    })
  }
}
