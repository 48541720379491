import { defineStore } from 'pinia'
import { handlebarFilters, handlebarType } from '@/types/handlebar'
import handlebarService from '@/service/handlebarService'

export const useHandlebarStore = defineStore('handlebarStore', {
  state: (): {
    handlebarData: handlebarType[]
    filters: handlebarFilters
  } => ({
    handlebarData: [],
    filters: {},
  }),
  getters: {},
  actions: {
    reset() {
      this.handlebarData = []
    },

    async retrieveHandlebars(sessionId: string) {
      const { data } = await handlebarService.fetchHandlebars({
        sessionId: sessionId,
        filters: this.filters,
      })

      if (data) this.handlebarData = data.Handlebars

      return data
    },

    async setFilters(newFilters: Record<string, any>) {
      if (JSON.stringify(newFilters) !== JSON.stringify(this.filters)) {
        // First concat new filters with current filters...
        this.filters = {
          ...this.filters,
          ...newFilters,
        }

        // ...then remove filters where new filter === false, rather than keep filter at false value.
        for (const [key, value] of Object.entries(newFilters)) {
          if (value === false)
            delete this.filters[key as keyof handlebarFilters]
        }
      }
    },
  },
})
