<template>
  <div class="filter-summary-item">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.filter-summary-item {
  color: var(--color-dark-60);
}
</style>
