import { defineStore } from 'pinia'
import { pedalFilters, pedalType } from '@/types/pedal'
import pedalService from '@/service/pedalService'

export const usePedalStore = defineStore('pedalStore', {
  state: (): {
    pedalData: pedalType[]
    filters: pedalFilters
  } => ({
    pedalData: [],
    filters: {},
  }),
  getters: {},
  actions: {
    reset() {
      this.pedalData = []
    },

    async retrievePedals(sessionId: string) {
      const { data } = await pedalService.fetchHandlebars({
        sessionId: sessionId,
        filters: this.filters,
      })

      if (data) this.pedalData = data.Pedals

      return data
    },

    async setFilters(newFilters: Record<string, any>) {
      if (JSON.stringify(newFilters) !== JSON.stringify(this.filters)) {
        // First concat new filters with current filters...
        this.filters = {
          ...this.filters,
          ...newFilters,
        }

        // ...then remove filters where new filter === false, rather than keep filter at false value.
        for (const [key, value] of Object.entries(newFilters)) {
          if (value === false) delete this.filters[key as keyof pedalFilters]
        }
      }
    },
  },
})
