import type { MqttContainer, MqttValue } from './types'

export const heartRate: MqttValue = {
  title: 'heart rate',
  unit: 'bpm',
  topic: 'bikefitting/heartrate/heart_rate',
}
export const rrTime: MqttValue = {
  title: 'rr time',
  unit: 'ms',
  topic: 'bikefitting/heartrate/rr_time',
}
export const accumulatedEventTime: MqttValue = {
  title: 'accumulated event time',
  unit: 'seconds',
  topic: 'bikefitting/heartrate/accumulated_eventtime',
}
export const heartRateDefinitions: MqttContainer = {
  title: 'Heart rate',
  prefix: 'heartrate/',
  values: [
    heartRate,
    //rrTime,
    //accumulatedEventTime,
  ],
}
export default heartRateDefinitions
