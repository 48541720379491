export enum bleDeviceType {
  resistanceWheel = 'resistanceWheel',
  heartRate = 'heartRate',
  xyTool = 'xyTool',
}

export type bleDevice = {
  name: string
  id: string
}
