export type bikeType = {
  CUSTOMER_ID: string
  BIKE_NO: number
  MAIN_BIKETYPE_ID: number
  SUB_BIKETYPE_ID: number
  PEDAL_HEIGHT: number
  PEDAL_CENTER: number
  PEDAL_BRAND: string // Enum? e.g. "(AVERAGE)"
  PEDAL_MODEL: string // Enum? e.g. "(AVERAGE)"
  SOLE_THICKNESS: number
  SHOES_BRAND: string // Enum? e.g. "(AVERAGE)"
  SHOES_MODEL: string // Enum? e.g. "(AVERAGE)"
  SADDLE_MIDDLE: string // Enum? e.g. "110", "120", etc
  SEAT_ANGLE: number
  CRANK_LENGTH: number
  NOTES: string // Enum? e.g. "Complete Fit"
  IS_DELETED?: any // TODO: define,
  UPDATE_DATE: string
  LOCK_VERSION_BIKE: number
  ORI_SADDLE_CENTER_X?: any // TODO: define,
  ORI_SADDLE_CENTER_Y?: any // TODO: define,
  ORI_HANDLEBAR_CENTER_X?: any // TODO: define,
  ORI_HANDLEBAR_CENTER_Y?: any // TODO: define,
  SF_SADDLE_CENTER_X: number
  SF_SADDLE_CENTER_Y: number
  SF_HANDLEBAR_CENTER_X: number
  SF_HANDLEBAR_CENTER_Y: number
  SF_DATETIME: string
  DF_SADDLE_CENTER_X?: any // TODO: define,
  DF_SADDLE_CENTER_Y?: any // TODO: define,
  DF_HANDLEBAR_CENTER_X?: any // TODO: define,
  DF_HANDLEBAR_CENTER_Y?: any // TODO: define,
  DF_DATETIME?: any // TODO: define,
  LAST_SADDLE_CENTER_X?: any // TODO: define,
  LAST_SADDLE_CENTER_Y?: any // TODO: define,
  LAST_HANDLEBAR_CENTER_X?: any // TODO: define,
  LAST_HANDLEBAR_CENTER_Y?: any // TODO: define,
  LAST_HANDLEBAR_WIDTH?: any // TODO: define,
  PA_DATETIME?: any // TODO: define,
  LOCK_VERSION_XY: number
  LOCK_VERSION_RES: number
  MAIN_BIKETYPE_NAME: string
  SUB_BIKETYPE_NAME: string
  DISPLAY_BIKE_NO: number
  MAIN_BIKETYPE_NAME_RES: string
  SUB_BIKETYPE_NAME_RES: string
  ANALYSIS_TYPE: number
  ORI_SADDLE_HEIGHT: number
  ORI_SADDLE_HANDLEBAR_DISTANCE: number
  ORI_SADDLE_HANDLEBAR_DROP: number
  ORI_SADDLE_HANDLES_DISTANCE: number
  IS_COORDINATES_XY?: any // TODO: define,
  ORI_PLUGIN_BRAND?: any // TODO: define,
  ORI_PLUGIN_MODEL?: any // TODO: define,
  ORI_PLUGIN_BRAND_SIZE?: any // TODO: define,
  ORI_PLUGIN_YEAR?: any // TODO: define,
  REV_SADDLE_CENTER_X?: any // TODO: define,
  REV_SADDLE_CENTER_Y?: any // TODO: define,
  REV_HANDLEBAR_CENTER_X?: any // TODO: define,
  REV_HANDLEBAR_CENTER_Y?: any // TODO: define,
  SF_SADDLE_HEIGHT?: any // TODO: define,
  SF_SADDLE_HANDLEBAR_DROP?: any // TODO: define,
  SF_SADDLE_HANDLEBAR_DISTANCE?: any // TODO: define,
  REV_SADDLE_HEIGHT?: any // TODO: define,
  REV_SADDLE_HANDLEBAR_DROP?: any // TODO: define,
  REV_SADDLE_HANDLEBAR_DISTANCE?: any // TODO: define,
  CURRENT_CLEAT_BRAND?: any // TODO: define,
  CURRENT_CLEAT_MODEL?: any // TODO: define,
  NEW_CLEAT_BRAND?: any // TODO: define,
  NEW_CLEAT_MODEL?: any // TODO: define
}

export type bikeSetup = {
  BikeTypeName?: any // TODO: define,
  CUSTOMER_ID: string
  CompanyName?: any // TODO: define,
  CustomerName?: any // TODO: define,
  DRAWING_NUMBER?: any // TODO: define,
  DealerName?: any // TODO: define,
  MAIN_BIKETYPE_NAME_RES?: any // TODO: define,
  Path?: any // TODO: define,
  REV_SADDLE_HANDLEBAR_DISTANCE?: any // TODO: define,
  REV_SADDLE_HANDLEBAR_DROP?: any // TODO: define,
  REV_SADDLE_HEIGHT?: any // TODO: define,
  SADDLE_HANDLEBAR_DISTANCE?: any // TODO: define,
  SADDLE_HANDLEBAR_DISTANCE_DIFF1?: any // TODO: define,
  SADDLE_HANDLEBAR_DROP?: any // TODO: define,
  SADDLE_HANDLEBAR_DROP_DIFF1?: any // TODO: define,
  SADDLE_HEIGHT?: any // TODO: define,
  SADDLE_HEIGHT_DIFF1?: any // TODO: define,
  SUB_BIKETYPE_NAME_RES?: any // TODO: define,
  lbBracketDrop?: any // TODO: define,
  lbCockpitLength?: any // TODO: define,
  lbCrankLength?: any // TODO: define,
  lbElbowToHandGrip?: any // TODO: define,
  lbFrameHeight?: any // TODO: define,
  lbFrameInclination?: any // TODO: define,
  lbFrameReach?: any // TODO: define,
  lbFrameStack?: any // TODO: define,
  lbHandleBarWidthTolerance?: any // TODO: define,
  lbHandlebarAngle?: any // TODO: define,
  lbHandlebarDrop?: any // TODO: define,
  lbHandlebarWidth?: any // TODO: define,
  lbHandlebarY?: any // TODO: define,
  lbHeadAngle?: any // TODO: define,
  lbPedalHeight?: any // TODO: define,
  lbRake?: any // TODO: define,
  lbReach?: any // TODO: define,
  lbSaddleCenterBrakeLever?: any // TODO: define,
  lbSaddleCenterBrakeLeverTolerance?: any // TODO: define,
  lbSaddleHeight?: any // TODO: define,
  lbSaddleMiddle?: any // TODO: define,
  lbSaddleSetBack?: any // TODO: define,
  lbSaddleWidth?: any // TODO: define,
  lbSaddleY?: any // TODO: define,
  lbSeatTubeAngle?: any // TODO: define,
  lbStemHeight?: any // TODO: define,
  lbTopTube?: any // TODO: define,
  lbVertFrameHeight?: any // TODO: define,
  setupResult?: {
    BracketDrop?: any // TODO: define,
    CockpitLength?: any // TODO: define,
    CrankLength?: any // TODO: define,
    Date?: any // TODO: define,
    ElbowToHandGrip?: any // TODO: define,
    FrameHeight?: any // TODO: define,
    FrameInclination?: any // TODO: define,
    FrameReach?: any // TODO: define,
    FrameStack?: any // TODO: define,
    HandleBarWidthTolerance?: any // TODO: define,
    HandlebarAngle?: any // TODO: define,
    HandlebarCenterX?: any // TODO: define,
    HandlebarCenterY?: any // TODO: define,
    HandlebarDrop?: any // TODO: define,
    HandlebarWidth?: any // TODO: define,
    HeadAngle?: any // TODO: define,
    HeadTube?: any // TODO: define,
    PedalHeight?: any // TODO: define,
    Rake?: any // TODO: define,
    Reach?: any // TODO: define,
    RecommendHandlebarCenterX?: any // TODO: define,
    RecommendHandlebarCenterY?: any // TODO: define,
    RecommendSaddleCenterX?: any // TODO: define,
    RecommendSaddleCenterY?: any // TODO: define,
    SaddleCenterToBrakeLever?: any // TODO: define,
    SaddleCenterToBrakeLeverTolerance?: any // TODO: define,
    SaddleCenterX?: any // TODO: define,
    SaddleCenterY?: any // TODO: define,
    SaddleHeight?: any // TODO: define,
    SaddleMiddle?: any // TODO: define,
    SaddleSetBack?: any // TODO: define,
    SaddleWidth?: any // TODO: define,
    SeatAngle?: any // TODO: define,
    StemHeight?: any // TODO: define,
    StemLength?: any // TODO: define,
    TopTubeLength?: any // TODO: define,
    VertFrameHeight?: any // TODO: define,
  }
}

export type bikeList = {
  Bikes: bikeType[]
}

export enum BikeCategory {
  CITY = 'city',
  CITYELECTRIC = 'city-electric',
  CYCLOCROSS = 'cyclocross',
  FULLTRIATHLON = 'full-triathlon',
  GRAVELELECTRIC = 'gravel-electric',
  GRAVEL = 'gravel',
  MTBCROSSCOUNTRY = 'mtb-cross-country',
  MTBELECTRIC = 'mtb-electric',
  MTBTRAIL = 'mtb-trail',
  ROADELECTRIC = 'road-electric',
  ROAD = 'road',
  SHORTTRIATHLON = 'short-triathlon',
  TIMETRIAL = 'time-trial',
  TREKKINGELECTRIC = 'trekking-electric',
  TREKKING = 'trekking',
}
