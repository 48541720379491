import { defineStore } from 'pinia'
import type { measurementType } from '@/types/measurement'
import type { bikeType, bikeSetup } from '@/types/bike'
import bikeService from '@/service/bikeService'

export const useBikeStore = defineStore('bikeStore', {
  state: (): {
    bikeNo: number
    bikeData?: bikeType
    bikeSetup?: bikeSetup
    bikePreview: string
    hasFitbike: boolean
    hasPositioningMotors: boolean
    hasXYTool: boolean
    has3DMotionAnalyzer: boolean
    has3DPedallingAnalyzer: boolean
    hasHeartRateMonitor: boolean
  } => ({
    bikeNo: 0,
    bikeData: undefined,
    bikeSetup: undefined,
    bikePreview: 'default',
    hasFitbike: true,
    hasPositioningMotors: true,
    hasXYTool: true,
    has3DMotionAnalyzer: true,
    has3DPedallingAnalyzer: true,
    hasHeartRateMonitor: true,
  }),
  getters: {},
  actions: {
    async retrieveBikes(
      customerId: string,
      dealerId: string,
      sessionId: string,
    ) {
      const { data } = await bikeService.fetchBikes({
        customerId: customerId,
        bikeNo: this.bikeNo,
        dealerId: dealerId,
        sessionId: sessionId,
      })

      if (data.Bikes.length === 1) {
        this.bikeData = data.Bikes[0]
      }

      return data
    },

    async updateXY(
      customerId: string,
      dealerId: string,
      sessionId: string,
      measurement: measurementType,
    ) {
      const { data } = await bikeService.putXY({
        customerId: customerId,
        bikeNo: this.bikeNo,
        dealerId: dealerId,
        sessionId: sessionId,
        measurement: measurement,
      })

      if (data.BikeSetup) {
        this.bikeSetup = data.BikeSetup
      }

      return data
    },
  },
  persist: {
    storage: sessionStorage,
  },
})
