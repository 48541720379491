import { useI18n } from 'vue-i18n'
import { BikeCategory } from '@/types/bike'

export const getBikeCategory = (
  mainBikeType: string | undefined,
  subBikeType: string | undefined,
): BikeCategory => {
  switch (mainBikeType?.toLowerCase()) {
    case 'mbkroad':
      return BikeCategory.ROAD

    case 'mbkcg':
      switch (subBikeType?.toLowerCase()) {
        case 'sbkcoc':
          return BikeCategory.CYCLOCROSS

        case 'sbkcgr':
          return BikeCategory.GRAVEL

        default:
          return BikeCategory.GRAVEL
      }

    case 'mbkmtb':
      return BikeCategory.MTBCROSSCOUNTRY

    case 'mbkmtbtrail':
      return BikeCategory.MTBTRAIL

    case 'mbktime':
      return BikeCategory.TIMETRIAL

    case 'mbktri':
      switch (subBikeType?.toLowerCase()) {
        case 'sbksd':
          return BikeCategory.SHORTTRIATHLON

        case 'sbkld':
          return BikeCategory.FULLTRIATHLON

        default:
          return BikeCategory.SHORTTRIATHLON
      }

    case 'mkbcitybike':
      return BikeCategory.CITY

    case 'mbktourtrek':
      return BikeCategory.TREKKING

    case 'mbkebike':
      switch (subBikeType?.toLowerCase()) {
        case 'sbkroad':
          return BikeCategory.ROADELECTRIC

        case 'sbkmtb':
          return BikeCategory.MTBELECTRIC

        case 'sbkcity':
          return BikeCategory.CITYELECTRIC

        case 'sbktouring':
          return BikeCategory.TREKKINGELECTRIC

        default:
          return BikeCategory.ROADELECTRIC
      }

    default:
      return BikeCategory.ROAD
  }
}

export const getHoodsTerm = (filter: number) => {
  const { t } = useI18n()

  switch (filter) {
    case 1:
      return t('common.bike.hoodsTypes.dropbar')

    case 100:
      return t('common.bike.hoodsTypes.flatbar')

    case 200:
      return t('common.bike.hoodsTypes.flatbar')

    case 221:
      return t('common.bike.hoodsTypes.dropbar')

    case 300:
      return t('common.bike.hoodsTypes.extensionBar')

    default:
      return t('common.bike.hoodsTypes.dropbar')
  }
}
