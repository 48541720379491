import { defineStore } from 'pinia'
import userService from '@/service/userService'
import { useBikeStore } from '@/store/bikeStore'
import { usePositionStore } from '@/store/positionStore'
import { useMeasurementStore } from '@/store/measurementStore'
import { useProgressStore } from '@/store/progressStore'

export const useUserStore = defineStore('userStore', {
  state: (): {
    loggedIn: boolean
    customerId?: string
    dealerId?: string
    dealerName?: string
    sessionId?: string
  } => ({
    loggedIn: false,
    customerId: undefined,
    dealerId: undefined,
    dealerName: undefined,
    sessionId: undefined,
  }),
  getters: {},
  actions: {
    async checkLogin(sessionId: string) {
      const { data } = await userService.fetchLoginStatus(sessionId)

      if (data.Authenticated) {
        this.loggedIn = true
        this.dealerId = data.DealerId
        this.dealerName = data.DealerName
        this.sessionId = sessionId
      } else {
        throw Error('Not authenticated')
      }
    },

    logout() {
      const bikeStore = useBikeStore()
      const positionStore = usePositionStore()
      const measurementStore = useMeasurementStore()
      const progressStore = useProgressStore()

      this.$reset()
      bikeStore.$reset()
      positionStore.$reset()
      measurementStore.$reset()
      progressStore.$reset()
    },
  },
  persist: {
    storage: sessionStorage,
  },
})
