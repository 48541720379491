import type { saddleFilters, saddleType } from '@/types/saddle'
import axios from 'axios'

export default class {
  static async fetchSaddles({
    sessionId = undefined,
    filters = undefined,
  }: {
    sessionId?: string
    filters?: saddleFilters
  } = {}) {
    return axios.post<saddleType>('/Hardware/RetrieveSaddles', {
      sessionId: sessionId,
      ...filters,
    })
  }
}
