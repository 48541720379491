import type { MqttContainer, MqttValue } from './types'
import {
  motorControllerPreSubmit,
  motorConversionValue,
} from './motorcontroller'

export const saddleCurrentSetPointX: MqttValue = {
  title: 'Current Setpoint X',
  unit: 'mm',
  topic: 'bikefitting/motorcontroller/0/current_setpoint',
  editable: true,
  preSubmitFunction: motorControllerPreSubmit,
  conversionValue: motorConversionValue,
}

export const saddleCurrentSetPointY: MqttValue = {
  title: 'Current Setpoint Y',
  unit: 'mm',
  topic: 'bikefitting/motorcontroller/1/current_setpoint',
  editable: true,
  preSubmitFunction: motorControllerPreSubmit,
  conversionValue: motorConversionValue,
}

export const saddleCurrentSetPoints: MqttContainer = {
  title: 'Current Setpoint',
  prefix: '',
  values: [saddleCurrentSetPointX, saddleCurrentSetPointY],
}

export const saddleCurrentPositionX: MqttValue = {
  title: 'Current Position X',
  unit: 'mm',
  topic: 'bikefitting/motorcontroller/0/current_pos',
  conversionValue: motorConversionValue,
}

export const saddleCurrentPositionY: MqttValue = {
  title: 'Current Position Y',
  unit: 'mm',
  topic: 'bikefitting/motorcontroller/1/current_pos',
  conversionValue: motorConversionValue,
}

export const saddleCurrentPosition: MqttContainer = {
  title: 'Current Position',
  prefix: '',
  values: [saddleCurrentPositionX, saddleCurrentPositionY],
}

export const saddleDefinitions: MqttContainer = {
  title: 'Saddle',
  prefix: 'motorcontroller/',
  values: [saddleCurrentSetPoints, saddleCurrentPosition],
}

export default saddleDefinitions
