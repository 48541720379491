import { createI18n } from 'vue-i18n'

import zh from './locales/zh.json'
import nl from './locales/nl.json'
import en from './locales/en.json'
import fr from './locales/fr.json'
import de from './locales/de.json'
import it from './locales/it.json'
import ja from './locales/ja.json'
import pt from './locales/pt.json'
import ptb from './locales/ptb.json'
import es from './locales/es.json'

export default createI18n({
  locale:
    localStorage.getItem('ui-language') ?? process.env.VUE_APP_DEFAULT_LOCALE,
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE,
  messages: {
    zh: zh,
    nl: nl,
    en: en,
    fr: fr,
    de: de,
    it: it,
    ja: ja,
    pt: pt,
    ptb: ptb,
    es: es,
  },
  legacy: false,
})
