import { defineStore } from 'pinia'
import type { saddleFilters, saddleType } from '@/types/saddle'
import saddleService from '@/service/saddleService'

export const useSaddleStore = defineStore('saddleStore', {
  state: (): {
    saddleData: saddleType[]
    filters: saddleFilters
  } => ({
    saddleData: [],
    filters: {},
  }),
  getters: {},
  actions: {
    reset() {
      this.saddleData = []
    },

    async retrieveSaddles(sessionId: string) {
      const { data } = await saddleService.fetchSaddles({
        sessionId: sessionId,
        filters: this.filters,
      })

      if (data) this.saddleData = data.Saddles

      return data
    },

    async setFilters(newFilters: Record<string, any>) {
      if (JSON.stringify(newFilters) !== JSON.stringify(this.filters)) {
        // First concat new filters with current filters...
        this.filters = {
          ...this.filters,
          ...newFilters,
        }

        // ...then remove filters where new filter === false, rather than keep filter at false value.
        for (const [key, value] of Object.entries(newFilters)) {
          if (value === false) delete this.filters[key as keyof saddleFilters]
        }

        // await this.retrieveSaddles
      }
    },
  },
})
