import { defineStore } from 'pinia'
import { ResistanceUnitMode } from '@/types/resistance'

export const useResistanceStore = defineStore('resistanceStore', {
  state: (): {
    mode: ResistanceUnitMode
  } => ({
    mode: ResistanceUnitMode.POWER_TARGET,
  }),
  getters: {},
  actions: {},
  persist: {},
})
