import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useUserStore } from '@/store/userStore'
import MainView from '@/views/MainView.vue'
import StartView from '@/views/start/StartView.vue'
import SetupView from '@/views/setup/SetupView.vue'
import SaddleSelectionView from '@/views/setup/SaddleSelectionView.vue'
import HandlebarSelectionView from '@/views/setup/HandlebarSelectionView.vue'
import PedalSelectionView from '@/views/setup/PedalSelectionView.vue'
import CalibrateView from '@/views/setup/CalibrateView.vue'
import AnalysisView from '@/views/analysis/AnalysisView.vue'
import DataView from '@/views/data/DataView.vue'
import CompletionView from '@/views/completion/CompletionView.vue'
import { useProgressStore } from '@/store/progressStore'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: MainView,
    meta: {
      step: 1,
    },
    children: [
      {
        path: '',
        name: 'start',
        component: StartView,
      },
    ],
  },
  {
    path: '/setup',
    component: MainView,
    meta: {
      requiresAuth: true,
      step: 2,
    },
    children: [
      {
        path: '',
        name: 'setup',
        component: SetupView,
      },
      {
        path: 'saddle',
        name: 'saddleSelection',
        meta: { requiresAuth: true },
        component: SaddleSelectionView,
      },
      {
        path: 'handlebar',
        name: 'handlebarSelection',
        meta: { requiresAuth: true },
        component: HandlebarSelectionView,
      },
      {
        path: 'pedal',
        name: 'pedalSelection',
        meta: { requiresAuth: true },
        component: PedalSelectionView,
      },
      {
        path: 'calibrate',
        name: 'calibrate',
        meta: { requiresAuth: true },
        component: CalibrateView,
      },
    ],
  },
  {
    path: '/analysis',
    meta: {
      requiresAuth: true,
      step: 3,
    },
    component: MainView,
    children: [
      {
        path: '',
        name: 'analysis',
        meta: { requiresAuth: true, update: true },
        component: AnalysisView,
      },
      {
        path: 'saddle',
        name: 'saddleUpdate',
        meta: { requiresAuth: true, update: true },
        component: SaddleSelectionView,
      },
      {
        path: 'handlebar',
        name: 'handlebarUpdate',
        meta: { requiresAuth: true, update: true },
        component: HandlebarSelectionView,
      },
      {
        path: 'pedal',
        name: 'pedalUpdate',
        meta: { requiresAuth: true, update: true },
        component: PedalSelectionView,
      },
    ],
  },
  {
    path: '/data',
    meta: {
      requiresAuth: true,
      step: 4,
    },
    component: MainView,
    children: [
      {
        path: '',
        name: 'data',
        component: DataView,
      },
    ],
  },
  {
    path: '/completion',
    meta: {
      requiresAuth: true,
      step: 5,
    },
    component: MainView,
    children: [
      {
        path: '',
        name: 'completion',
        component: CompletionView,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to) => {
  const userStore = useUserStore()

  if (to.meta.requiresAuth && !userStore.loggedIn) {
    return { name: 'start' }
  }

  if (to.meta.step) {
    useProgressStore().setStep(to.meta.step)
  }

  return true
})

export default router

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
    step?: number
  }
}
