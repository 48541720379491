import type { pedalType, pedalFilters } from '@/types/pedal'
import axios from 'axios'

export default class {
  static async fetchHandlebars({
    sessionId = undefined,
    filters = undefined,
  }: {
    sessionId?: string
    filters?: pedalFilters
  } = {}) {
    return axios.post<pedalType>('Hardware/RetrievePedals', {
      sessionId: sessionId,
      ...filters,
    })
  }
}
