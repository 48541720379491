<template>
  <div class="filter-summary">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.filter-summary {
  display: flex;
  gap: 1.6rem;
  flex-wrap: wrap;
  margin-top: 4.8rem;
}
</style>
