import type { userType } from '@/types/user'
import axios from 'axios'

export default class {
  static async fetchLoginStatus(sessionId: string) {
    return axios.post<userType>('/Account/WhoAmI', {
      SessionId: sessionId,
    })
  }
}
